import { Link } from "gatsby";
import Image from "../../../../src/components/image";
import imgTambolaCell from "../../../../articles/2022/05/01-tambola-cell-styling/images/tambola-cell.png";
import imgOutputCell from "../../../../articles/2022/05/01-tambola-cell-styling/images/output-cell.png";
import * as React from 'react';
export default {
  Link,
  Image,
  imgTambolaCell,
  imgOutputCell,
  React
};