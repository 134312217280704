import Image from "../../../../src/components/image";
import imgStack from "../../../../articles/2023/01/31-1-stack-javascript/images/stack.png";
import imgMemory from "../../../../articles/2023/01/31-1-stack-javascript/images/memory.png";
import imgLinkedMemory from "../../../../articles/2023/01/31-1-stack-javascript/images/linked-memory.png";
import imgNode5 from "../../../../articles/2023/01/31-1-stack-javascript/images/node-5.png";
import imgObjectReference from "../../../../articles/2023/01/31-1-stack-javascript/images/object-reference.png";
import imgCopyObjectReference from "../../../../articles/2023/01/31-1-stack-javascript/images/copy-object-reference.png";
import imgLetterBox from "../../../../articles/2023/01/31-1-stack-javascript/images/letter-box.png";
import imgFirstNode from "../../../../articles/2023/01/31-1-stack-javascript/images/first-node-in-memory.png";
import * as React from 'react';
export default {
  Image,
  imgStack,
  imgMemory,
  imgLinkedMemory,
  imgNode5,
  imgObjectReference,
  imgCopyObjectReference,
  imgLetterBox,
  imgFirstNode,
  React
};