import Image from "../../../../src/components/image";
import imgCodeStorageSize from "../../../../articles/2023/07/01-aws-lambda-codestorage-exception/images/code-storage-size.png";
import imgVersionNumbers from "../../../../articles/2023/07/01-aws-lambda-codestorage-exception/images/version-numbers.png";
import imgCleanedVersionNumbers from "../../../../articles/2023/07/01-aws-lambda-codestorage-exception/images/cleaned-versions.png";
import * as React from 'react';
export default {
  Image,
  imgCodeStorageSize,
  imgVersionNumbers,
  imgCleanedVersionNumbers,
  React
};