import Image from "../../../../src/components/image";
import imgSingleBundle from "../../../../articles/2022/03/13-code-splitting-import-function/images/single-bundle.png";
import imgSingleOutput from "../../../../articles/2022/03/13-code-splitting-import-function/images/single-output.png";
import imgCodeSplit from "../../../../articles/2022/03/13-code-splitting-import-function/images/code-split.png";
import * as React from 'react';
export default {
  Image,
  imgSingleBundle,
  imgSingleOutput,
  imgCodeSplit,
  React
};