import Image from "../../../../src/components/image";
import ImageTishkuGithub from "../../../../articles/2021/03/01-deploy-nextjs-site-in-vercel-with-custom-domain/images/github-tishku.png";
import ImageVercelWebsite from "../../../../articles/2021/03/01-deploy-nextjs-site-in-vercel-with-custom-domain/images/vercel-website.png";
import ImageVercelHome from "../../../../articles/2021/03/01-deploy-nextjs-site-in-vercel-with-custom-domain/images/vercel-home.png";
import ImageVercelConnected from "../../../../articles/2021/03/01-deploy-nextjs-site-in-vercel-with-custom-domain/images/vercel-connected-github.png";
import ImageVercelDeploymentProgress from "../../../../articles/2021/03/01-deploy-nextjs-site-in-vercel-with-custom-domain/images/vercel-deployment-progress.png";
import ImageVercelDeploymentSuccess from "../../../../articles/2021/03/01-deploy-nextjs-site-in-vercel-with-custom-domain/images/vercel-deployment-success.png";
import ImageDomainARecord from "../../../../articles/2021/03/01-deploy-nextjs-site-in-vercel-with-custom-domain/images/vercel-domain-arecord.png";
import * as React from 'react';
export default {
  Image,
  ImageTishkuGithub,
  ImageVercelWebsite,
  ImageVercelHome,
  ImageVercelConnected,
  ImageVercelDeploymentProgress,
  ImageVercelDeploymentSuccess,
  ImageDomainARecord,
  React
};