import { Link } from "gatsby";
import Image from "../../../../src/components/image";
import imgHardhatNodes from "../../../../articles/2022/02/16-deploy-smart-contract/images/hardhat-nodes.png";
import imgHardhatDeploy from "../../../../articles/2022/02/16-deploy-smart-contract/images/deploy-output.png";
import * as React from 'react';
export default {
  Link,
  Image,
  imgHardhatNodes,
  imgHardhatDeploy,
  React
};