import Olink from "../../../../src/components/olink";
import Image from "../../../../src/components/image";
import imgCorrettoInstaller from "../../../../articles/2022/09/07-install-jdk-mac-m1/images/corretto-installer.png";
import imgJavaVersion from "../../../../articles/2022/09/07-install-jdk-mac-m1/images/java-version.png";
import * as React from 'react';
export default {
  Olink,
  Image,
  imgCorrettoInstaller,
  imgJavaVersion,
  React
};