import Image from "../../../../src/components/image";
import imgCloudfrontIds from "../../../../articles/2022/11/05-clear-cloudfront-cache/images/cloudfront-ids.png";
import imgInvalidations from "../../../../articles/2022/11/05-clear-cloudfront-cache/images/invalidations.png";
import imgCreateInvalidation from "../../../../articles/2022/11/05-clear-cloudfront-cache/images/create-invalidation.png";
import * as React from 'react';
export default {
  Image,
  imgCloudfrontIds,
  imgInvalidations,
  imgCreateInvalidation,
  React
};