import Image from "../../../../src/components/image";
import imgBrowserConsole from "../../../../articles/2022/09/15-js-playground/images/browser-console.png";
import imgNodeVersion from "../../../../articles/2022/09/15-js-playground/images/node-version.png";
import imgRunUsingNode from "../../../../articles/2022/09/15-js-playground/images/run-using-node.png";
import imgCodePen from "../../../../articles/2022/09/15-js-playground/images/codepen.png";
import imgRunJS from "../../../../articles/2022/09/15-js-playground/images/runjs.png";
import Olink from "../../../../src/components/olink";
import * as React from 'react';
export default {
  Image,
  imgBrowserConsole,
  imgNodeVersion,
  imgRunUsingNode,
  imgCodePen,
  imgRunJS,
  Olink,
  React
};