import Image from "../../../../src/components/image";
import imgDevelopmentMode from "../../../../articles/2022/03/09-webpack-mode-config/images/development-mode.png";
import imgProductionMode from "../../../../articles/2022/03/09-webpack-mode-config/images/production-mode.png";
import imgNoneMode from "../../../../articles/2022/03/09-webpack-mode-config/images/none-mode.png";
import * as React from 'react';
export default {
  Image,
  imgDevelopmentMode,
  imgProductionMode,
  imgNoneMode,
  React
};