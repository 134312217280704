import Image from "../../../../src/components/image";
import imgEmptySquare from "../../../../articles/2022/04/02-tambola-cell-component/images/empty-square.png";
import imgCellWithNumber from "../../../../articles/2022/04/02-tambola-cell-component/images/cell-with-number.png";
import imgCalledNumber from "../../../../articles/2022/04/02-tambola-cell-component/images/called-number.png";
import * as React from 'react';
export default {
  Image,
  imgEmptySquare,
  imgCellWithNumber,
  imgCalledNumber,
  React
};