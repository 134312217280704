import Image from "../../../../src/components/image";
import imgGitlabRepo from "../../../../articles/2021/12/02-setup-gitlab-cicd-host-nextjs-aws-lamba/images/gitlab-repo.png";
import imgGitlabYML from "../../../../articles/2021/12/02-setup-gitlab-cicd-host-nextjs-aws-lamba/images/create-gitlab-yml.png";
import imgServerlessDeploying from "../../../../articles/2021/12/02-setup-gitlab-cicd-host-nextjs-aws-lamba/images/serverless-deploying.png";
import imgServerlessDeployed from "../../../../articles/2021/12/02-setup-gitlab-cicd-host-nextjs-aws-lamba/images/serverless-deployed.png";
import imgSLSAdded from "../../../../articles/2021/12/02-setup-gitlab-cicd-host-nextjs-aws-lamba/images/serverless-added.png";
import imgAddVariables from "../../../../articles/2021/12/02-setup-gitlab-cicd-host-nextjs-aws-lamba/images/add-variables.png";
import imgVariablesSection from "../../../../articles/2021/12/02-setup-gitlab-cicd-host-nextjs-aws-lamba/images/variables-section.png";
import imgCICDdeployed from "../../../../articles/2021/12/02-setup-gitlab-cicd-host-nextjs-aws-lamba/images/ci-cd-deployed.png";
import * as React from 'react';
export default {
  Image,
  imgGitlabRepo,
  imgGitlabYML,
  imgServerlessDeploying,
  imgServerlessDeployed,
  imgSLSAdded,
  imgAddVariables,
  imgVariablesSection,
  imgCICDdeployed,
  React
};