import Image from "../../../../src/components/image";
import imgExclamation from "../../../../articles/2022/07/02-emmet-shortcut-html5-vscode/images/exclamation-emmet.png";
import imgHTML5Emmet from "../../../../articles/2022/07/02-emmet-shortcut-html5-vscode/images/html5-emmet.png";
import imgGeneratedHTML from "../../../../articles/2022/07/02-emmet-shortcut-html5-vscode/images/generated-html5.png";
import * as React from 'react';
export default {
  Image,
  imgExclamation,
  imgHTML5Emmet,
  imgGeneratedHTML,
  React
};