import { Link } from "gatsby";
import Olink from "../../../../src/components/olink";
import Image from "../../../../src/components/image";
import imgDistFolder from "../../../../articles/2022/04/08-host-static-react-s3/images/dist-folder.png";
import imgBucketPolicy from "../../../../articles/2022/04/08-host-static-react-s3/images/bucket-policy.png";
import imgPublicAccess from "../../../../articles/2022/04/08-host-static-react-s3/images/public-access.png";
import imgStaticWebsiteHosting from "../../../../articles/2022/04/08-host-static-react-s3/images/static-website-hosting.png";
import * as React from 'react';
export default {
  Link,
  Olink,
  Image,
  imgDistFolder,
  imgBucketPolicy,
  imgPublicAccess,
  imgStaticWebsiteHosting,
  React
};