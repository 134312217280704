import Olink from "../../../../src/components/olink";
import Image from "../../../../src/components/image";
import imgLogo from "../../../../articles/2022/10/06-introduction-to-extension-dev/images/logo.png";
import imgExtensionIcons from "../../../../articles/2022/10/06-introduction-to-extension-dev/images/extension-icons.png";
import imgPopupDesign from "../../../../articles/2022/10/06-introduction-to-extension-dev/images/popup-design.png";
import imgExtensionTab from "../../../../articles/2022/10/06-introduction-to-extension-dev/images/extension-tab.png";
import imgExtensionTile from "../../../../articles/2022/10/06-introduction-to-extension-dev/images/extension-tile.png";
import imgConsole from "../../../../articles/2022/10/06-introduction-to-extension-dev/images/inspect-element.png";
import imgErrorBtn from "../../../../articles/2022/10/06-introduction-to-extension-dev/images/error-btn.png";
import imgErrorDetails from "../../../../articles/2022/10/06-introduction-to-extension-dev/images/error-details.png";
import imgExtensionTileWithoutIcon from "../../../../articles/2022/10/06-introduction-to-extension-dev/images/extension-tile-without-icon.png";
import imgTileWithIcon from "../../../../articles/2022/10/06-introduction-to-extension-dev/images/tile-with-icon.png";
import * as React from 'react';
export default {
  Olink,
  Image,
  imgLogo,
  imgExtensionIcons,
  imgPopupDesign,
  imgExtensionTab,
  imgExtensionTile,
  imgConsole,
  imgErrorBtn,
  imgErrorDetails,
  imgExtensionTileWithoutIcon,
  imgTileWithIcon,
  React
};