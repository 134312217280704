import Divider from "../../../../src/components/divider";
import Image from "../../../../src/components/image";
import imagePagesFolder from "../../../../articles/2021/03/05-nextjs-interview-questions-routing-dynamic-routes-catch-all/images/pages-folder.png";
import imageSameURLPagesFolder from "../../../../articles/2021/03/05-nextjs-interview-questions-routing-dynamic-routes-catch-all/images/same-url-pages-folder.png";
import * as React from 'react';
export default {
  Divider,
  Image,
  imagePagesFolder,
  imageSameURLPagesFolder,
  React
};