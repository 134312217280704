import Image from "../../../../src/components/image";
import imgProductionMap from "../../../../articles/2022/03/11-understanding-source-maps-webpack/images/production-map.png";
import imgDevelopmentMap from "../../../../articles/2022/03/11-understanding-source-maps-webpack/images/development-map.png";
import imgInlineSourceMap from "../../../../articles/2022/03/11-understanding-source-maps-webpack/images/inline-source-map.png";
import * as React from 'react';
export default {
  Image,
  imgProductionMap,
  imgDevelopmentMap,
  imgInlineSourceMap,
  React
};