import Image from "../../../../src/components/image";
import imgFirstComponent from "../../../../articles/2021/04/14-write-your-first-react-app/images/first-react-component.png";
import imgComponentWithStyle from "../../../../articles/2021/04/14-write-your-first-react-app/images/component-with-style.png";
import imgComponentWithJSX from "../../../../articles/2021/04/14-write-your-first-react-app/images/component-using-jsx.png";
import * as React from 'react';
export default {
  Image,
  imgFirstComponent,
  imgComponentWithStyle,
  imgComponentWithJSX,
  React
};