import imagePrototypeProperty from "../../../../articles/2020/02/04-prototypes-in-javascript/images/prototype-property.png";
import imageDuntoProto from "../../../../articles/2020/02/04-prototypes-in-javascript/images/__proto__.png";
import imageDuntoProto1 from "../../../../articles/2020/02/04-prototypes-in-javascript/images/__proto__1.png";
import imageDuntoProto2 from "../../../../articles/2020/02/04-prototypes-in-javascript/images/__proto__2.png";
import Image from "../../../../src/components/image";
import * as React from 'react';
export default {
  imagePrototypeProperty,
  imageDuntoProto,
  imageDuntoProto1,
  imageDuntoProto2,
  Image,
  React
};