import { Link } from "gatsby";
import Olink from "../../../../src/components/olink";
import Image from "../../../../src/components/image";
import imgTambolaScreens from "../../../../articles/2022/04/07-tambola-product-development-part-1/images/tambola-screens.png";
import * as React from 'react';
export default {
  Link,
  Olink,
  Image,
  imgTambolaScreens,
  React
};