import Answer from "../../../../src/components/answer";
import QuestionItem from "../../../../src/components/questionitem";
import Question from "../../../../src/components/question";
import Option from "../../../../src/components/option";
import Explanation from "../../../../src/components/explanation";
import Divider from "../../../../src/components/divider";
import * as React from 'react';
export default {
  Answer,
  QuestionItem,
  Question,
  Option,
  Explanation,
  Divider,
  React
};