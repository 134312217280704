import Image from "../../../../src/components/image";
import imgCreateNextApp from "../../../../articles/2021/12/01-host-nextjs-app-aws-lambda/images/create-next-app.png";
import imgAWSCredentials from "../../../../articles/2021/12/01-host-nextjs-app-aws-lambda/images/aws-credentials.png";
import imgNextjsLambda from "../../../../articles/2021/12/01-host-nextjs-app-aws-lambda/images/nextjs-deployed-lambda.png";
import imgDeployedApp from "../../../../articles/2021/12/01-host-nextjs-app-aws-lambda/images/deployed-app.png";
import * as React from 'react';
export default {
  Image,
  imgCreateNextApp,
  imgAWSCredentials,
  imgNextjsLambda,
  imgDeployedApp,
  React
};