import Olink from "../../../../src/components/olink";
import Image from "../../../../src/components/image";
import { Link } from "gatsby";
import imgTermsConditions from "../../../../articles/2022/10/07-publish-chrome-extension/images/terms-conditions.png";
import imgPaymentComplete from "../../../../articles/2022/10/07-publish-chrome-extension/images/registration-complete.png";
import imgPublisherDetails from "../../../../articles/2022/10/07-publish-chrome-extension/images/publisher-details.png";
import imgExtensionParsed from "../../../../articles/2022/10/07-publish-chrome-extension/images/parsed-extension.png";
import imgPrivacyPolicies from "../../../../articles/2022/10/07-publish-chrome-extension/images/privacy-policies.png";
import imgSubmitForReview from "../../../../articles/2022/10/07-publish-chrome-extension/images/submit-for-review.png";
import imgEmail from "../../../../articles/2022/10/07-publish-chrome-extension/images/email.png";
import imgExtensionPage from "../../../../articles/2022/10/07-publish-chrome-extension/images/extension-page.png";
import * as React from 'react';
export default {
  Olink,
  Image,
  Link,
  imgTermsConditions,
  imgPaymentComplete,
  imgPublisherDetails,
  imgExtensionParsed,
  imgPrivacyPolicies,
  imgSubmitForReview,
  imgEmail,
  imgExtensionPage,
  React
};