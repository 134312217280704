import Image from "../../../../src/components/image";
import imgWebpackError from "../../../../articles/2022/03/07-webpack-loaders/images/webpack-error.png";
import imgWebpackCSS from "../../../../articles/2022/03/07-webpack-loaders/images/webpack-css.png";
import imgWebpackStyleLoader from "../../../../articles/2022/03/07-webpack-loaders/images/webpack-style-loader.png";
import imgWebpackHTML from "../../../../articles/2022/03/07-webpack-loaders/images/webpack-html.png";
import imgStyleInsert from "../../../../articles/2022/03/07-webpack-loaders/images/style-insert.png";
import * as React from 'react';
export default {
  Image,
  imgWebpackError,
  imgWebpackCSS,
  imgWebpackStyleLoader,
  imgWebpackHTML,
  imgStyleInsert,
  React
};