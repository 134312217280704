import Olink from "../../../../src/components/olink";
import Image from "../../../../src/components/image";
import imgLiveServer from "../../../../articles/2022/07/03-dev-server-live-server-vscode/images/live-server-extension.png";
import imgLiveServerSearch from "../../../../articles/2022/07/03-dev-server-live-server-vscode/images/live-server-search.png";
import imgOpenLiveServer from "../../../../articles/2022/07/03-dev-server-live-server-vscode/images/open-live-server.png";
import * as React from 'react';
export default {
  Olink,
  Image,
  imgLiveServer,
  imgLiveServerSearch,
  imgOpenLiveServer,
  React
};