import { Link } from "gatsby";
import Image from "../../../../src/components/image";
import imgExtensionPack from "../../../../articles/2022/09/09-vs-code-java/images/extension-pack-java.png";
import imgJavaOutput from "../../../../articles/2022/09/09-vs-code-java/images/java-output.png";
import * as React from 'react';
export default {
  Link,
  Image,
  imgExtensionPack,
  imgJavaOutput,
  React
};