import Image from "../../../../src/components/image";
import imgSSROutput from "../../../../articles/2022/09/02-isomorphic-working/images/ssr-output.png";
import imgSSRSource from "../../../../articles/2022/09/02-isomorphic-working/images/ssr-source.png";
import imgSSRComponent from "../../../../articles/2022/09/02-isomorphic-working/images/ssr-component.png";
import imgSSRCalls from "../../../../articles/2022/09/02-isomorphic-working/images/ssr-calls.png";
import * as React from 'react';
export default {
  Image,
  imgSSROutput,
  imgSSRSource,
  imgSSRComponent,
  imgSSRCalls,
  React
};