import { Link } from "gatsby";
import Image from "../../../../src/components/image";
import imgWebpackDist from "../../../../articles/2022/03/06-webpack-output-config/images/webpack-output-dist.png";
import imgWebpackLog from "../../../../articles/2022/03/06-webpack-output-config/images/webpack-output-log.png";
import * as React from 'react';
export default {
  Link,
  Image,
  imgWebpackDist,
  imgWebpackLog,
  React
};