import { Link } from "gatsby";
import Olink from "../../../../src/components/olink";
import Image from "../../../../src/components/image";
import imgGithubSecrets from "../../../../articles/2022/04/11-deploy-react-app-s3-github-actions/images/github-secrets.png";
import imgGithubActions from "../../../../articles/2022/04/11-deploy-react-app-s3-github-actions/images/github-actions.png";
import * as React from 'react';
export default {
  Link,
  Olink,
  Image,
  imgGithubSecrets,
  imgGithubActions,
  React
};