import imageBrendan from "../../../../articles/2020/11/20-javascript-introduction/images/brendan-eich.jpg";
import imageMosaic from "../../../../articles/2020/11/20-javascript-introduction/images/mosaic-browser.webp";
import imageMosaicNetscape from "../../../../articles/2020/11/20-javascript-introduction/images/mosaic-netscape.webp";
import imageJSTrademark from "../../../../articles/2020/11/20-javascript-introduction/images/javascript-trademark.png";
import imageJavaScriptPosition from "../../../../articles/2020/11/20-javascript-introduction/images/javascript-position.png";
import Image from "../../../../src/components/image";
import * as React from 'react';
export default {
  imageBrendan,
  imageMosaic,
  imageMosaicNetscape,
  imageJSTrademark,
  imageJavaScriptPosition,
  Image,
  React
};